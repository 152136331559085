<template>
    <div>        
        <b-card-code title="Tablero de Control" no-body>   
            <b-row v-if="this.showWrongProjects == true" class="mb-2 ml-2">
                <b-col>
                    <h4 style="color:red">
                        {{ alertText }} por favor revisar al final de la lista     
                    </h4>                    
                </b-col> 
            </b-row>

            <b-table                 
                ref="selectableTable"
                :fields="fields" 
                :items="projectsDetails" 
                :tbody-tr-class="rowClass"               
                responsive   
                selectable             
                class="mb-0"  
                :select-mode="selectMode"
                @row-selected="onRowSelected"                
                >
                <template #cell(startDate)="data">                    
                    {{((data.item.startDate).substring(0,10))}}                    
                </template> 
                
                <template #cell(endDate)="data">
                    {{((data.item.endDate).substring(0,10))}}                    
                </template> 

                <template #cell(daysFromLastEvent)="data">                    
                   <span>{{((data.item.daysFromLastEvent))}} días <span style="font-size:10px">({{((data.item.lastEventDate).substring(0,10))}})</span></span>
                </template> 

                <template #cell(daysFromLastWeight)="data">
                    <span v-if="data.item.daysFromLastWeight == 'Nunca'">
                        {{((data.item.daysFromLastWeight))}} días <span style="font-size:10px">({{((data.item.lastWeightDate).substring(0,10))}})</span>
                    </span>
                        <span v-else>{{((data.item.daysFromLastWeight))}} días <span style="font-size:10px">({{((data.item.lastWeightDate).substring(0,10))}})</span>
                    </span>
                </template> 
            </b-table> 
            
        </b-card-code>
        <b-row v-if="this.projectsDetails.length <= 0" class="justify-content-center mt-3" style="text-align:center">
            <b-col style="justify-content:center" xl="4" md="6" lg="4"  >
                <b-alert variant="primary" show>
                    <div class="alert-body">
                        <span><strong>No Hay Información para mostrar</strong></span>
                    </div>
                </b-alert>
            </b-col>
        </b-row>

        <b-modal centered ref="my-modal" hide-footer title="¿Que deseas hacer?">
            <b-button class="mt-1" variant="primary" block @click="viewProjectPublicDetails">
                <feather-icon icon="EyeIcon" class="mr-50"/>
                <span class="align-middle">Ver Proyecto</span>
            </b-button> 
            <b-button class="mt-2" variant="warning" block @click="followUpProject">
                <feather-icon icon="ClipboardIcon" class="mr-50"/>
                <span class="align-middle">Seguimiento Proyecto</span>
            </b-button>
        </b-modal>

    </div>  
  </template>

  <script>
  
  import { BLink, BButton, BImg, BSpinner,BRow, BCol, BFormInput, BFormTextarea, BFormGroup,
         BTable, BAlert } from 'bootstrap-vue'
  import BCardCode from '@/@core/components/b-card-code/BCardCode.vue';   
  import axios from '@axios'
  
  export default {
    components: { 
        BAlert,       
        BLink,
        BButton,
        BImg,
        BSpinner,
        BRow,
        BCol,
        BFormInput,
        BFormTextarea,
        BFormGroup,
        BTable,
        BCardCode
    },
    data() {
      return {
        selectMode: 'single',
        fields: [
            { key: 'line', label: 'Item' }, 
            { key: 'projectCode', label: 'Codigo de Proyecto' }, 
            { key: 'projectStatus', label: 'Estado de Proyecto'},
            { key: 'farmName', label: 'Finca' }, 
            { key: 'projectType', label: 'Tipo' },  
            { key: 'startDate', label: 'Fecha Inicio' },
            { key: 'endDate', label: 'Fecha Final' },
            { key: 'daysFromLastEvent', label: 'Días desde último Evento' },
            { key: 'daysFromLastWeight', label: 'Días desde último Pesaje' },
            { key: 'totalDays', label: 'Total Dias' },            
        ],
        projectsDetails:[{}], 
        alertText: "Los siguientes proyectos tienen información inconsistente: ",
        showWrongProjects: false,
        selectedProject:{}
      }
    },
    created() {
      this.getProjectsInformationForControlPanel()
    },  
 
    methods:{
        getProjectsInformationForControlPanel(){
            axios.get('/Project/GetProjectsInformationForControlPanel')
            .then(response => {                
                this.projectsDetails = response.data
                this.projectsDetails.forEach(item => {
                    if(item.daysFromLastEvent < 0 || item.daysFromLastWeight < 0){  
                        this.showWrongProjects = true;                      
                        this.alertText = this.alertText + '#' + item.projectCode + ', '
                    }
                    item.line = this.projectsDetails.indexOf(item) + 1
                });
            })
        },
        rowClass(item, type) {

            if (item.totalDays > 120) {
                { return 'table-danger' }
            }
            else if (item.totalDays > 80 && item.totalDays <= 120)  {
                { return 'table-warning' }
            }
            else if (item.totalDays > 50 && item.totalDays <= 80)  {
                { return 'table-info' }
            }
            else if (item.totalDays < 0 || item.daysFromLastEvent < 0 || item.daysFromLastWeight < 0 )  {
                { return 'table-secondary' }
            }
        },
        onRowSelected(items) {    
            this.selectedProject = items[0];       
            if(this.selectedProject) this.showModal();
        },
        viewProjectPublicDetails() {
            this.$refs['my-modal'].hide()
            let route = this.$router.resolve({path: 'public-project-details?projectCode=' + this.selectedProject.projectCode});
            window.open(route.href, '_blank');
        },
        followUpProject(){
            this.$refs['my-modal'].hide()            
            let route = this.$router.resolve({path: 'follow-up-project?projectID=' + this.selectedProject.projectID});
            window.open(route.href, '_blank');
        },        
        showModal(){          
            this.$refs['my-modal'].show()
        },
     },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/pages/page-misc.scss';
  </style>
  